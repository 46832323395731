import React from 'react'
import styled from 'styled-components'
import { useSalesSupplyOrderTracking } from '../../hooks/useThirdPartyScripts'
import {
  mobileVW,
  desktopVW,
  desktopBreakpoint,
  headerHeight,
  color,
  border
} from '../../styles'

const TrackingBody = ({ title }) => {
  useSalesSupplyOrderTracking()

  return (
    <StyledTrackingBody>
      <Title>{title}</Title>

      <Wrapper>
        <div id='trackingPageDiv' />
        <iframe id='trackingPageIframe' />
      </Wrapper>
    </StyledTrackingBody>
  )
}

const StyledTrackingBody = styled.div`
  position: relative;
  width: 100%;
  padding-top: calc(${headerHeight.mobile} + ${mobileVW(30)});
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;

  @media (min-width: ${desktopBreakpoint}) {
    padding-top: calc(${headerHeight.desktop} + ${desktopVW(60)});
  }
`

const Title = styled.h1`
  font-size: ${mobileVW(60)};
  line-height: ${mobileVW(51)};
  word-break: break-word;
  text-align: center;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(150)};
    line-height: ${desktopVW(142)};
    word-break: break-word;
    padding: 0 ${desktopVW(240)};
  }
`

const Wrapper = styled.div`
  position: relative;
  padding: ${mobileVW(16)};
  top: -${mobileVW(30)};

  #trackingPageDiv {
    width: 100%;
    font-family: inherit!important;
    background-color: ${color.yellow};
    padding: ${mobileVW(50)};

    #mainTitle {
      margin: ${mobileVW(20)} 0;
      color: ${color.black};
    }
    
    .inputElement {
      font-size: ${mobileVW(16)};
      line-height: ${mobileVW(24)};
      color: ${color.black};
      font-weight: bold;
      border: none;
      border-bottom: ${border.medium};
      background-color: transparent;
      border-radius: 0;
      padding: 0;
      margin-bottom: ${mobileVW(16)};
      
      &::placeholder {
        color: ${color.black};
      }

      &:focus {
        outline: none;
      }
    }

    #trackingNumberButton {
      font-size: ${mobileVW(12)};
      line-height: ${mobileVW(10)};
      border-radius: 0;
      text-transform: uppercase;
      text-decoration: none;
      display: flex;
      height: ${mobileVW(65)};
      justify-content: center;
      align-items: center;
      width: 100%;
      cursor: pointer;
      position: relative;
      bottom: 0;
      background-color: ${color.black};
      color: ${color.white};
    }
  }

  #trackingPageIframe {
    border: ${border.medium};
    height: ${mobileVW(520)};
    font-family: inherit!important;

    .title {
      text-transform: uppercase!important;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin: auto;
    top: -${desktopVW(110)};

    #trackingPageDiv {
      max-width: ${desktopVW(1200)}!important;
      padding: ${desktopVW(60)};

      #mainTitle {
        margin: ${desktopVW(20)} 0;
      }
      
      .inputElement {
        font-size: ${desktopVW(16)};
        line-height: ${desktopVW(24)};
        margin-bottom: ${desktopVW(20)};
      }
  
      #trackingNumberButton {
        font-size: ${desktopVW(13)};
        line-height: ${desktopVW(20)};
        height: ${desktopVW(65)};
      }
    }
  
    #trackingPageIframe {
      height: ${desktopVW(920)};
      min-width: ${desktopVW(900)};
      max-width: ${desktopVW(1200)};
      padding: 0;
    }
  }
`

export default TrackingBody
